<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-16 18:23:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 12:08:57
 * @FilePath: /mediatom-web/src/views/accountInfo/account/components/ResetApiKey/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="reset-key-container">
    <a-form-model :model="resetkeyform" labelAlign="left" ref="resetkeyform" v-bind="layout">
      <a-form-model-item >
        <m-tips
          slot="label"
          :content="`开发者在${title}平台的身份ID，在请求${title} Open API时使用。`"
          :title="'API_KEY'"
          :width="220"
        ></m-tips>
        <a-input style="width:560px" v-model.trim="resetkeyform.apiKey" disabled>
          <a-button size="small" slot="addonBefore" @click="copyKey(resetkeyform.apiKey)" type="link"> 复制 </a-button
          ><a-button size="small" slot="addonAfter" type="link" @click="changeApi(0)"> 重置 </a-button>
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <m-tips
          slot="label"
          :content="`${title}平台提供了综合报表查询的API接口，使用${title} Open API拉取数据时需使用API Secret Key生成Sign进行接口权限校验。`"
          :title="'API_SECRET'"
          :width="220"
        ></m-tips>
        <a-input style="width:560px" v-model.trim="resetkeyform.apiSecret" disabled>
          <a-button size="small" slot="addonBefore" @click="copyKey(resetkeyform.apiSecret)" type="link"> 复制 </a-button
          ><a-button size="small" slot="addonAfter" type="link" @click="changeApi(1)"> 重置 </a-button>
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { copy } from '@/utils/string'
export default {
  name: 'Resetkeyform',
  data () {
    return {
      resetkeyform: {
        apiKey: undefined,
        apiSecret: undefined
      },
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      }
    }
  },
  computed: {
    ...mapState({
      apiSecret: (state) => state.user.apiSecret,
      apiKey: (state) => state.user.apiKey,
      title: (state) => state.autoweb.title
    })
  },
  created () {},
  watch: {
    apiKey: {
      handler (val) {
        this.resetkeyform.apiKey = val
      },
      deep: true,
      immediate: true
    },
    apiSecret: {
      handler (val) {
        this.resetkeyform.apiSecret = val
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeApi (type) {
      this.$store.dispatch('changeKey', type)
    },
    copyKey (value) {
      const result = copy(value)
      if (result) {
        this.$message.success('复制成功！')
      } else {
        this.$message.warning('复制失败！')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.reset-key-container {
  width: 500px;
}
::v-deep.ant-form-item {
  margin-bottom: 20px;
}
</style>
